import {PageProps, graphql, navigate, useStaticQuery} from 'gatsby'
import React, {useEffect, useState} from 'react'
import {animated as a, useSpring} from 'react-spring'

import BackSvg from '../images/svgs/back.svg'
import Cart from '../components/gallery/cart/Cart'
import Emoji from '../components/shared/emoji'
import Layout from '../components/ui/layout'
import LogoAmex from '../images/svgs/payment/amex.svg'
import LogoMasterCard from '../images/svgs/payment/mastercard.svg'
import LogoStripe from '../images/svgs/blurple.svg'
import LogoVisa from '../images/svgs/payment/visa.svg'
import SEO from '../components/shared/seo'
import ghSvg from '../images/gh.svg'
import mailSvg from '../images/mail.svg'
import {motion} from 'framer-motion'
import posed from 'react-pose'
import styled from 'styled-components'
import {type} from 'os'
import {useLayoutValue} from '../components/ui/layoutProvider'
import {useMediaQuery} from 'react-responsive'

const lineVariant = {
  enter: {
    x: 0,
    opacity: 0,
    width: '0%',
    background: 'rgba(255, 255, 255, 1)',
    // transition: {
    //   background: {ease: 'linear', damping: 20, delay: 100},
    //   width: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
    // },
  },
  exit: {
    x: 0,
    width: '100%',
    opacity: 100,
    background: 'rgba(0, 0, 0, 1)',
    // transition: {
    //   background: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
    //   width: {ease: 'linear', damping: 500, delay: 100, duration: 1000},
    // },
  },
}

const LinkUnderline = styled(motion.div)`
  position: relative;
  /* height: 1rem; */
  border-bottom: 1px;
  border-style: solid;
`

// animated react spring div
const Divider = styled(a.div)`
  position: relative;
  border-bottom: 1px;
  border-color: white;
  border-style: solid;
  will-change: width, opacity;
`

const Faq: React.FC<PageProps<any>> = ({props}) => {
  const {state, dispatch} = useLayoutValue()
  const [loading, set] = useState(false)
  const {width, opacity} = useSpring({
    width: `${loading ? 100 : 0}%`,
    opacity: loading ? 0 : 1,
    config: {mass: 2, tension: 200, friction: 80},
  })
  useEffect(() => {
    set(true)
  }, [])

  return (
    <>
      <SEO
        title="About | John Dore"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />
      {/* <main className="flex flex-col  md:self-center"> */}
      <div className="flex md:flex-row text-white justify-between  w-full  bg-gray tracking-widest">
        <div className="mx-10  text-white text-5xl md:text-7xl pt-20 leading-header font-normal">
          FAQ
        </div>
        <button
          className="m-2 mx-4 self-end"
          onClick={() => {
            navigate(-1)
          }}>
          <BackSvg className="h-8" />
        </button>
      </div>
      <div className="text-justify text-sm  ">
        <div className="text-4xl mx-10 mt-10 leading-header">Resources</div>
        <div className="w-screen border-b border-black mb-10"></div>
        <div className="my-4 flex flex-col md:w-3/4 w-full px-10">
          <div className="mt-2 leading-header text-xl mb-4 underline text-white">
            Want to learn more?
          </div>

          <a
            className="hover:underline hover:text-white text-base"
            href="https://github.com/terkelg/awesome-creative-coding"
            target="_blank">
            awesome-creative-coding <Emoji symbol=" 📌" />
          </a>
          <a
            className="hover:underline hover:text-white text-base"
            href="https://github.com/beardicus/awesome-plotters"
            target="_blank">
            awesome-plotters <Emoji symbol=" 📌" />
          </a>
          <a
            className="hover:underline hover:text-white mt-2 font-medium text-base"
            href="https://natureofcode.com/book/introduction/"
            target="_blank">
            The Nature of Code - Daniel Shiffman <Emoji symbol="✨🎈" />
          </a>
        </div>
        <div className="text-4xl mx-10 mt-10 leading-header">Artwork</div>
        <div className="w-screen border-b border-black mb-10"></div>
        <div className="my-4 flex flex-col md:w-3/4 w-full px-10">
          All artwork for sale in the gallery has an associated page in the work section of the
          website (and from the link in the footer on gallery page). This is the place to find more
          information about any current exhibit. Most of my current work revolves around
          programmatically generated .SVG files and is produced by a mechanical plotter.
          <div className="mt-4 leading-header text-2xl text-white">Mechanical Plotter?</div>
          <Divider
            className="mb-4"
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
          <div>
            I currently use the{' '}
            <a
              className="hover:underline text-white"
              href="https://shop.evilmadscientist.com/productsmenu/890">
              Axidraw v3 (A3 size)
            </a>{' '}
            designed and manufactured by Evil Mad Scientist. There are various other mechanical
            plotters out there, (MakeBlock) and they are by no means a totally{' '}
            <a
              className="hover:underline text-white text-sm "
              href="https://en.wikipedia.org/wiki/Plotter">
              new way of producing work.
            </a>
            <div className="my-2">
              I believe the fact that you can use a wide array of different drawing implements and
              media greatly seperates mechanical plotters from contemporary printers. In short the
              difference between plot and print is the relationship between the machine and it's
              operator - which lends alot from traditional printing and drawing practices.
            </div>
          </div>
          <div className="mt-2 leading-header text-2xl text-white">Materials?</div>
          <Divider
            className="mb-4"
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
          <div className="mb-2 italic">
            Materials such as the pens and paper used on a given plot will be listed when applicable
            on the description of the work for sale.
          </div>
          <div>
            I generally plot on Bristol board, and have used products by Canson, Fabriano and Frisk.
            I'm always trialing different types of pens and inks. But have had a lot of good results
            using Pigma Micron Pens despite them have a short lifespan. I also use ballpoints such
            as the Pilot Hi-Tec-C 0.25mm as they are really durable, however the longevity and
            archivability of the ink is an unknown. Rotring's Isographs albeit pricey, are also a
            good choice as you can experiment with ink.
          </div>
        </div>
        <div className="text-4xl  mx-10 mt-10 leading-header">Shipping & Packaging</div>
        <div className="w-screen border-b border-black mb-10"></div>
        <div className="my-4 flex flex-col md:w-3/4 w-full px-10">
          Based in London UK, all work will be shipped with Royal Mail using their tracked & signed
          for first class service. Global shipping is available and currently the two flat shipping
          rates are:{' '}
          <ul className="my-2">
            <li>UK Domestic: £4.99 Royal Mail special delivery guaranteed</li>
            <li>Rest of World: £14.99 Royal Mail international tracked</li>
          </ul>{' '}
          <div className="my-2">
            Your artwork will come in a cardboard mailer with backing to protect it, sealed inside a
            compostable cellophane wrapper. All eco-friendly packaging purchased from
            <a className="hover:underline text-white text-sm" href="https://www.eco-craft.co.uk/">
              {' '}
              eco-craft.co.uk
            </a>
            <div className="my-2 font-medium">Please note artwork does not come framed.</div>
          </div>
          <div>Once your order is shipped you will receive a notification via email.</div>
          <div className="font-bold my-2">
            All artwork is non-refundable, however if any damage occurs during transit or there is
            an issue with the work once you recieve it, please get in touch.
          </div>
          {/* <div className="font-bold text-xl">
            **Shipping has now closed for Christmas, deliveries will resume as normal on 4th
            January.
          </div> */}
        </div>
        <div className="text-4xl  mx-10 mt-10 leading-header">Payment </div>
        <div className="w-screen border-b border-black mb-10"></div>

        <div className="my-4 flex flex-col md:w-3/4 w-full px-10">
          <div className="flex flex-row mb-8 text-lg items-center">
            All transactions are
            <a href="https://stripe.com/" target="_blank">
              <LogoStripe className="ml-4 w-32 self-center " />
            </a>
          </div>
          <div className="border-b w-full max-w-lg border-white "></div>

          <div className="flex flex-row my-2">
            <LogoVisa className="w-12 h-12 mr-2" />
            <LogoAmex className="w-12 h-12 mr-2" />
            <LogoMasterCard className="w-12 h-12  mr-2" />
            <div className="italic text-xs self-end">*accepted cards</div>
          </div>
          <div className="border-b w-full max-w-lg border-white mb-2"></div>
        </div>
        <div className="text-4xl  mx-10 mt-10 leading-header">Privacy Policy</div>
        <div className="w-screen border-b border-black mb-10"></div>
        <div className="my-4 flex flex-col md:w-3/4 w-full mb-10 px-10">
          <div>
            John Dore is committed to protecting and respecting your privacy.<br></br> This website
            uses Stripe for transactions. You can find their privacy policy @{' '}
            <a
              className=" hover:underline hover:text-white"
              href="https://stripe.com/en-gb/privacy">
              https://stripe.com/en-gb/privacy
            </a>
            <br></br>
            <div className="flex flex-col my-2">
              <span className="font-bold">
                Personal information will only be used for the following purposes:
              </span>
              <span className="mt-2">
                Completing transactions in accordance with the Stripe API.{' '}
              </span>
              <span>Notification of product shipment.</span>
              <span className="my-2 italic">
                John Dore will never share or sell your personal information.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq
